import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import router from './router'
import  storage from 'store'
import 'ant-design-vue/dist/antd.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.use(Antd);


import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// http://momentjs.cn/docs/#/use-it/
import moment from 'moment'
moment.locale('zh-cn')

const allowList = ['login','fileshared'] // no redirect allowList
const loginRoutePath = '/vitransfer/login'

router.beforeEach((to, from, next) => {
  if (to.meta.title){
    document.title = to.meta.title
  }

  const token = storage.get("Access-Token")
  if (token) {
    next()
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
    }
  }
})

//判断是否移动端的函数
const isMobileFunc = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  if (flag === null) {
      return 0;
  } else {
      return 1;
  }
};

//设置html的字号大小，作为rem的计算依据
const setHtmlFontSize = () => {
  let designWidth = 375;
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (isMobileFunc() === 1 || htmlWidth < 500) {
      //移动端
      console.info("htmlDom.style.fontSize0",htmlDom.style.fontSize)
      htmlDom.style.fontSize = `${htmlWidth / designWidth}px`;
      console.info("htmlDom.style.fontSize1",htmlDom.style.fontSize)
  } else {
      //非移动端:
      //console.log('htmlWidth:'+htmlWidth);
      // let dest = htmlWidth * 0.36;
      // if (dest < 360) {
      //     dest = 360;
      // }
      // htmlDom.style.fontSize = `${dest / designWidth}px`;
  }
};

window.onresize = setHtmlFontSize;
setHtmlFontSize();


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
